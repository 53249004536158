 <template>
  <div>
    <div class="container-fluid">
      <PageTitle :noAdd="true" />

      <b-tabs>
        <template v-for="(vlang,klang) in lang">
          <b-tab :title="`Page Settings - ${vlang.text}`" :key="klang">
            <bo-card title="Hero Image" footer-tag="footer">
              <b-row class="gutter-2">
                <b-col md="3">
                  <bo-card-img
                    :src="uploader(((content['hero_' + vlang.value] || {}).value || {}).img)"
                    :title="((content['hero_' + vlang.value] || {}).value || {}).title"
                    :isTitleHtml="true"
                  >
                    <template #buttons>
                      <b-button
                        v-if="moduleRole('Edit')"
                        variant="secondary"
                        size="sm"
                        pill
                        @click="editHeroImage(content['hero_' + vlang.value])"
                        v-b-tooltip.hover="'Edit'"
                      >
                        <i class="fas fa-pencil-alt" />
                      </b-button>
                    </template>
                  </bo-card-img>
                </b-col>
              </b-row>
            </bo-card>

            <!-- <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5 class="card-title">Content Section</h5>
                  </div>
                  <div class="col-6 ml-auto">
                    <div class="text-right">
                      <button
                        @click.prevent="toggleEditForm(`content_section_${vlang.value}`)"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fas fa-edit m-r-10"></i> Update Content
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver
                      v-slot="{ handleSubmit }"
                      :ref="`VFormContent${vlang.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'content-section-setting',
                              content['content_' + vlang.value],
                              `VFormContent${vlang.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <div class="col-12">
                            <div class="row">
                              <div class="col-12">
                                <div class="form-group">
                                  <label>
                                    Description
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <template v-if="editFormId == `content_section_${vlang.value}`">
                                    <ckeditor
                                      :editor="editor"
                                      v-model="((content['content_' + vlang.value] || {}).value || {}).value"
                                      :config="editorConfig"
                                    ></ckeditor>
                                    <VValidate
                                      :vid="`description${vlang.value}`"
                                      name="Description"
                                      v-model="((content['content_' + vlang.value] || {}).value || {}).value"
                                      :rules="(mrValidation.content_setting || {}).description"
                                    />
                                  </template>
                                  <template v-else>
                                    <div v-html="((content['content_' + vlang.value] || {}).value || {}).value"></div>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <b-col md="12" v-if="editFormId == `content_section_${vlang.value}`">
                            <div class="text-right mt-4">
                              <b-button
                                type="button"
                                @click="cancelEditForm"
                                class="btn btn-rounded btn_light mr-2"
                              >
                                Cancel
                              </b-button>
                              <b-button 
                                v-if="moduleRole('Edit')"
                                type="submit"
                                variant="primary"
                                class="btn-rounded"
                              >
                                Update
                              </b-button>
                            </div>
                          </b-col>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div> -->

            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5 class="card-title">Content Section</h5>
                  </div>
                  <div class="col-6 ml-auto">
                    <div class="text-right">
                      <button
                        @click.prevent="toggleEditForm(`content_section_${vlang.value}`)"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fas fa-edit m-r-10"></i> Update Content
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver
                      v-slot="{ handleSubmit }"
                      :ref="`VFormContent${vlang.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'content-section-setting',
                              content['content_' + vlang.value],
                              `VFormContent${vlang.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <template v-for="(v,k) in (content['content_' + vlang.value] || {}).value">
                            <div class="row" :key="k">
                              <template v-if="editFormId == `content_section_${vlang.value}` && k==0">
                                <div class="col-12">
                                  <div class="border rounded mb-3 px-4 py-3 d-flex justify-content-center align-items-center">
                                    <div class="d-flex align-items-center text-decoration-underline">
                                      <a href="javascript:void(0)" @click="addNewItem((content['content_' + vlang.value] || {}).value, k, true)">
                                        <i class="fa fa-plus-circle"></i>
                                        <span class="mx-2">Add New</span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </template>
                              <div class="col-12">
                                <div class="border rounded mb-3 px-4 py-3">
                                  <template v-if="editFormId != `content_section_${vlang.value}`">
                                    <div class="row">
                                      <div class="col-md-5">
                                        <h5 class="font-weight-bold mb-0">{{ v['title'] }}</h5>
                                      </div>
                                      <div class="col-md-7">
                                        <div v-html="v['description']"></div>
                                      </div>
                                    </div>
                                  </template>
                                  <template v-else>
                                    <div class="button-el d-flex justify-content-between">
                                      <span># {{ k + 1}}</span>
                                      <b-button
                                        v-if="moduleRole('Edit')"
                                        variant="danger"
                                        size="sm"
                                        pill
                                        v-b-tooltip.hover="'Delete'"
                                        @click="deleteItemData((content['content_' + vlang.value] || {}).value, k)"
                                      >
                                        <i class="fas fa-trash-alt" />
                                      </b-button>
                                    </div>
                                    <div class="row">
                                      <div class="col-md-5">
                                        <label for="">Title <span class="text-danger">*</span></label>
                                        <b-form-input
                                          :id="`title${k}${vlang.value}`"
                                          placeholder="e.g. Insurance provider"
                                          v-model="v['title']"
                                        ></b-form-input>
                                        <VValidate
                                          :vid="`title${k}${vlang.value}`"
                                          name="Title"
                                          v-model="v['title']"
                                          :rules="(mrValidation.content_data || {}).title"
                                        />
                                      </div>
                                      <div class="col-md-7">
                                        <div class="form-group">
                                          <label>
                                            Description
                                            <span class="text-danger">*</span>
                                          </label>
                                          <ckeditor
                                            :editor="editor"
                                            v-model="v['description']"
                                            :config="editorConfig"
                                          ></ckeditor>
                                          <VValidate
                                            :vid="`description${vlang.value}`"
                                            name="Description"
                                            v-model="v['description']"
                                            :rules="(mrValidation.content_data || {}).description"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                </div>
                              </div>
                              <template v-if="editFormId == `content_section_${vlang.value}`">
                                <div class="col-12">
                                  <div class="border rounded mb-3 px-4 py-3 d-flex justify-content-center align-items-center">
                                    <div class="d-flex align-items-center text-decoration-underline">
                                      <a href="javascript:void(0)" @click="addNewItem((content['content_' + vlang.value] || {}).value, k)">
                                        <i class="fa fa-plus-circle"></i>
                                        <span class="mx-2">Add New</span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </template>
                          <hr />
                          <b-col md="12" v-if="editFormId == `content_section_${vlang.value}`">
                            <div class="text-right mt-4">
                              <b-button
                                type="button"
                                @click="cancelEditForm"
                                class="btn btn-rounded btn_light mr-2"
                              >
                                Cancel
                              </b-button>
                              <b-button 
                                v-if="moduleRole('Edit')"
                                type="submit"
                                variant="primary"
                                class="btn-rounded"
                              >
                                Update
                              </b-button>
                            </div>
                          </b-col>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>

          </b-tab>
        </template>

        <template v-for="(vlangseo,klangseo) in lang">
          <b-tab :title="`SEO Settings - ${vlangseo.text}`" :key="`seo_${klangseo}`">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <div class="col-10">
                    <h5 class="card-title">SEO Settings</h5>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver 
                      v-slot="{ handleSubmit }" 
                      :ref="`VFormSeo${vlangseo.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'seo-setting',
                              content['seo_' + vlangseo.value],
                              `VFormSeo${vlangseo.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <div class="col-12">
                            <div class="row">
                              <div class="col-12 col-md-8">
                                <div class="form-group">
                                  <label :for="`seoTitle${vlangseo.value}`">
                                    Title
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <template v-if="editFormId == `seo${vlangseo.value}`">
                                    <b-form-input
                                      :id="`seoTitle${vlangseo.value}`"
                                      v-model="((content['seo_' + vlangseo.value] || {}).value || {}).title"
                                      :formatter="(v) => v.replace(/\s\s\s+/, ' ')"
                                    ></b-form-input>
                                    <VValidate
                                      :vid="`seoTitle${vlangseo.value}`"
                                      name="Title"
                                      v-model="((content['seo_' + vlangseo.value] || {}).value || {}).title"
                                      rules="required|min:3|max:200"
                                    />
                                  </template>
                                  <template v-else>
                                    <p class="font-weight-bold">{{ ((content['seo_' + vlangseo.value] || {}).value || {}).title }}</p>
                                  </template>
                                </div>
                              </div>
                              <div class="col-12 col-md-8">
                                <div class="form-group">
                                  <label>
                                    Description
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <template v-if="editFormId == `seo${vlangseo.value}`">
                                    <b-form-textarea
                                      :id="`seoDescription${vlangseo.value}`"
                                      v-model="((content['seo_' + vlangseo.value] || {}).value || {}).description"
                                      :formatter="(v) => v.replace(/\s\s\s+/, ' ')"
                                      rows="5"
                                    ></b-form-textarea>
                                    <VValidate
                                      :vid="`seoDescription${vlangseo.value}`"
                                      name="Description"
                                      v-model="((content['seo_' + vlangseo.value] || {}).value || {}).description"
                                      rules="required|min:10|max:500"
                                    />
                                  </template>
                                  <template v-else>
                                    <div v-html="((content['seo_' + vlangseo.value] || {}).value || {}).description"></div>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row" v-if="moduleRole('Edit')">
                          <div class="col-12">
                            <hr />
                            <div class="text-right">
                              <template v-if="editFormId == `seo${vlangseo.value}`">
                                <button
                                  type="button"
                                  @click="editFormId = ''"
                                  class="btn btn-rounded btn-light mr-2"
                                >
                                  Cancel
                                </button>
                                <button type="submit" class="btn btn-rounded btn-primary">
                                  Save
                                </button>
                              </template>
                              <template v-else>
                                <button
                                  type="button"
                                  @click="editFormId = `seo${vlangseo.value}`"
                                  class="btn btn-rounded btn-primary"
                                >
                                  Ubah
                                </button>
                              </template>
                            </div>
                          </div>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </template>
      </b-tabs>

      <ValidationObserver v-slot="{ handleSubmit }" ref="VFormHeroImage">
        <b-modal
          id="heroImageModal"
          size="md"
          title="Edit Hero Image"
          @ok.prevent="
            handleSubmit(
              updateContent(
                'hero-image',
                inputHeroImage,
                'VFormHeroImage',
                'heroImageModal'
              )
            )
          "
        >
          <template #default>
            <div class="infoHTML"></div>
            <b-form 
              @submit.prevent="
                  handleSubmit(
                    updateContent(
                      'hero-image',
                      inputHeroImage,
                      'VFormHeroImage'
                    )
                  )
                "
            >
              <b-row>
                <b-col md="12">
                  <BoField 
                    label="Sub Title"
                    placeholder="e.g. INSURANCE PROTECTION"
                    v-model="((inputHeroImage || {}).value || {}).sub_title"
                    :rules="mrValidation.sub_title"
                  />
                </b-col>
                <b-col md="12">
                  <BoField 
                    label="Title"
                    placeholder="e.g. Jakarta Running Festival 2024"
                    v-model="((inputHeroImage || {}).value || {}).title"
                    :rules="mrValidation.title"
                  />
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label>
                      Image <span class="text-danger mr5">*</span>
                    </label>
                    <Uploader
                      :readonly="!moduleRole('Edit')"
                      v-model="((inputHeroImage || {}).value || {}).fallback_img"
                      :param="{ thumbnail: true }"
                      :squarePreview="true"
                      type="hero_image"
                      label="Image"
                      @data="v => ((inputHeroImage || {}).value || {}).img = v"
                    />
                    <VValidate
                      name="Image"
                      v-model="((inputHeroImage || {}).value || {}).img"
                      :rules="mrValidation.hero_image.img"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label>
                      Small Image <span class="text-danger mr5">*</span>
                    </label>
                    <Uploader
                      :readonly="!moduleRole('Edit')"
                      v-model="((inputHeroImage || {}).value || {}).fallback_img_small"
                      :param="{ thumbnail: true }"
                      :squarePreview="true"
                      type="hero_small"
                      label="Image"
                      @data="v => ((inputHeroImage || {}).value || {}).img_small = v"
                    />
                    <VValidate
                      name="Image"
                      v-model="((inputHeroImage || {}).value || {}).img_small"
                      :rules="mrValidation.hero_image.img_small"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <BoField 
                    label="Alt Image"
                    placeholder="e.g. Hero Image"
                    v-model="((inputHeroImage || {}).value || {}).alt_img"
                    :rules="mrValidation.alt_img"
                  />
                </b-col>
                <hr />
              </b-row>
            </b-form>
          </template>

          <template #modal-footer="{ ok, cancel }">
            <b-button variant="outline-secondary" @click="cancel()">
              Cancel
            </b-button>
            <b-button variant="info" @click="ok()">
              Submit
            </b-button>
          </template>
        </b-modal>
      </ValidationObserver>

    </div>
  </div>
</template>

<script>
import GlobalVue from "@libs/Global";
import PageTitle from "@view/include/PageTitle";

import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@libs/ckeditor5-custom-build/build/ckeditor";
import { extend } from "vee-validate";
import assign from "lodash/assign";

import BoCard from '@/components/BoCard.vue'
import BoCardImg from '@/components/BoCardImg.vue'

export default {
  extends: GlobalVue,

  components: {
    PageTitle,
    BoCard,
    BoCardImg,
    ckeditor: CKEditor.component,
  },

  data() {
    return {
      // GENERAL
      lang: [
        {
          text: "English",
          value: "en",
        },
        {
          text: "Indonesian",
          value: "id",
        }
      ],
      editor: ClassicEditor,
      editorConfig: {
        fontColor: {
          colors: [
            {
              color: "hsl(221, 99%, 43%)",
              label: "Blue",
            },
            {
              color: "hsl(0, 83%, 47%)",
              label: "Red",
            },
            {
              color: "hsl(93, 69%, 49%)",
              label: "Green",
            },
            {
              color: "hsl(322, 100%, 62%)",
              label: "Pink",
            },
            {
              color: "hsl(0, 0%, 0%)",
              label: "Black",
            },
            {
              color: "hsl(0, 0%, 30%)",
              label: "Dim grey",
            },
            {
              color: "hsl(0, 0%, 60%)",
              label: "Grey",
            },
            {
              color: "hsl(0, 0%, 90%)",
              label: "Light grey",
            },
            {
              color: "hsl(0, 0%, 100%)",
              label: "White",
              hasBorder: true,
            },
          ],
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
            {
              model: "heading3",
              view: "h3",
              title: "Heading 3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view: "h4",
              title: "Heading 4",
              class: "ck-heading_heading4",
            },
            {
              model: "heading5",
              view: "h5",
              title: "Heading 5",
              class: "ck-heading_heading5",
            },
            {
              model: "heading6",
              view: "h6",
              title: "Heading 6",
              class: "ck-heading_heading6",
            },
          ],
        },
        ui: {
          viewportOffset: {
            top: 65,
          },
        },
        toolbar: {
          items: ["heading", "|", "bold", "fontColor", "link", 'numberedList', 'bulletedList', 'imageUpload', "insertTable", 'sourceEditing'],
        },
      },

      // ROW
      content: {},

      editFormId: "",

      // INPUT
      inputHeroImage: {}
    };
  },

  created() {
    this.apiGet();
  },

  methods: {
    // General

    updateContent(type, input, IDFORM, modalId = false, usingKey = false) {
      this.doSubmit(
        "/do/" + this.modulePage,
        assign(
          {
            type,
          },
          {},
          {
            as_value: input,
          }
        ),
        (type) => {
          if (type === "success") {
            this.apiGet();
            this.editFormId = "";
            if (modalId) {
              this.$bvModal.hide(modalId);
            }
          }
        },
        "POST",
        IDFORM,
        usingKey
      );
    },

    // Hero Image
    editHeroImage(data) {
      this.inputHeroImage = {
        ...data
      }

      this.$bvModal.show('heroImageModal')
    },

    addNewItem(data, index, isFirst = false) {
      let inputData = {
        title: '',
        description: '',
      }

      if(isFirst) {
        data.unshift(inputData);
      } else {
        data.splice(index + 1, 0, inputData);
      }
      
    },

    deleteItemData(data, index) {
      if(index > -1) {
        data.splice(index, 1);
      }
    }

  },
  mounted() {
    extend("editor", {
      message: "The {_field_} field must be at least 10 characters",
      validate: (value) => {
        try {
          return this.sanitize(value).length >= 10;
        } catch (error) {
          console.log(error);
        }
      },
    });
  },
}
  
</script>

<style>
table {
  width: 100%; /* Optional, ensures the table fills the container */
  margin-bottom: 0; /* Remove default bottom margin */
  border-collapse: collapse; /* Collapse borders for seamless appearance */
}

th,
td {
  padding: 0.5rem; /* Set padding for cells */
  vertical-align: top; /* Vertically align content within cells */
  border: 1px solid #dee2e6; /* Add borders to cells */
}

thead th {
  color: #495057; /* Set text color for table headers */
  background-color: #e9ecef; /* Set background color for table headers */
  border-bottom: 2px solid #dee2e6; /* Thicker border for bottom of headers */
}
</style>